button.cookiesButton {
  position: absolute;
  top: -35px;
  color: #9797a5;
  font-size: 0.95em;
  background: none;
  padding: 0.2rem 2rem;
  border-radius: 50px;
  border: none;
  cursor: pointer;
}
button.cookiesButton:hover {
  color: #fff;
}

.pop-promo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  width: 100vw;
  background: rgba(0, 0, 0, 0.87);
  height: -webkit-fill-available;
  z-index: 3;
}
.pop-container {
  border-radius: 14px;
  width: 45%;
  position: absolute;
  background: #ffffff;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  align-content: center;
  justify-content: center;
  align-content: center;
  -webkit-box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.57);
}
.pop-closeBtn {
  position: absolute;
  margin: 1rem;
  top: 0;
  right: 0;
}
.pop-close-icon {
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.pop-content {
  display: flex;
  flex-basis: 60%;
  align-items: center;
}
.pop-image-container {
  flex-basis: 40%;
  overflow: hidden;
  height: 100%;
  margin-top: 5em;
}
.pop-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 14px 0 0 14px;
}
.pop-info {
  padding: 3em;
}
  .pop-title {
  font-size: 2.15rem;
  line-height: 2.8rem;
  font-weight: 600;
  color: var(--primary-color);
}
.pop-subtitle {
  font-size: 1.2em;
  line-height: 1.25em;
  color: var(--secondary-color);
}
.pop-text-info {
  margin-top: 1rem;
  line-height: 1.5em;
  font-size: 1em;
  font-weight: 300;
  color: var(--text-color);
}
.pop-call-to-action {
  margin-top: 2rem;
}
.pop-btn {
  border: navajowhite;
  background: var(--secondary-color);
  color: #ffffff;
  padding: 0.5rem 2rem;
  font-weight: 400;
  font-size: 1.2rem;
  border-radius: 50px;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.51);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.51);
}


/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) {
  .pop-container {
    width: 90%;
  }
  .pop-content {
    flex-basis: 90%;
  }
  .pop-close-icon {
    width: 25px;
    height: 25px;
  }
  .pop-image-container {
    flex-basis: unset;
    overflow: hidden;
    height: 180px;
    width: 100%;
  }
  .pop-info {
    padding: 2em 1em 1em 1em;
  }
}

@media (min-width: 321px) and (max-width: 574px) {
  .pop-container {
    width: 90%;
  }
  .pop-content {
    flex-basis: 90%;
  }
  .pop-close-icon {
    width: 25px;
    height: 25px;
  }
  .pop-info {
    padding: 2em 1em 1em 1em;
  }
  .pop-image-container {
    flex-basis: unset;
    overflow: hidden;
    height: 200px;
    width: 100%;
    margin-top: 0em;
  }
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {
  .pop-container {
    width: 90%;
  }
  .pop-info {
    padding: 3em;
  }
  .pop-content {
    flex-basis: 100%;
  }
  .pop-image-container {
    flex-basis: unset;
    overflow: hidden;
    height: 350px;
    width: 100%;
    margin-top: 0em;
  }
}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {
  .pop-container {
    width: 90%;
  }
  .pop-info {
    padding: 3em;
  }
  .pop-content {
    flex-basis: 100%;
  }
  .pop-image-container {
    flex-basis: unset;
    overflow: hidden;
    height: 390px;
    width: 100%;
    margin-top: 0em;
  }
}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {
  .pop-container {
    width: 90%;
  }
  .pop-image-container {
    margin-top: 5em;
  }
}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {
  .pop-container {
    width: 82%;
  }
  .pop-image-container {
    margin-top: 5em;
  }
}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {
  .pop-container {
    width: 80%;
  }
  .pop-image-container {
    margin-top: 3em;
  }
}

/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {
  .pop-container {
    width: 75%;
  }
  .pop-image-container {
    margin-top: 3em;
  }
}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
  .pop-container {
    width: 75%;
  }
  .pop-image-container {
    margin-top: 2em;
  }
}