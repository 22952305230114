.selected-promo {
  background: var(--success-color);
  width: 10px;
  height: 80px;
  position: absolute;
}
.page-info {
  height: unset;
  overflow-y: hidden;
}

.promo-row {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  margin-right: 1em;
}

.promo-card {
  margin: 0.5em 1em 0.5em 0em;
  padding: 0.5em;
  background: var(--card-bg-color);
  border-radius: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
  cursor: pointer;
}
.card-promo-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}
.promo-img {
  width: 70px;
  height: 70px;
}

.card-promo-info {
  width: 50%;
  margin: 0 1rem 0 1rem;
  padding: 1%;
}

.card-title {
  font-size: 1.25em;
  font-weight: 800;
  margin: 0em 0em 0.25em 0em;
  color: var(--secondary-color) !important;
}

.card-text {
  font-size: 0.75em;
  line-height: 1.3;
  font-weight: 300;
  margin: 0;
}

.promo-card.promo-selected {
  background: var(--secondary-color);
}
.promo-card.promo-selected .card-promo-info .card-title {
  color: #ffffff !important;
}
.promo-card.promo-selected .card-promo-info .card-text {
  color: #ffffff !important;
}

/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) { 
  .promo-row {
    margin-right: 0em;
  }
  .form-container.promo-form {
    margin-top: 1.5em;
  }
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
  .promo-row {
    margin-right: 0em;
  }
  .form-container.promo-form {
    margin-top: 1.5em;
  }
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {
  .promo-row {
    margin-right: 0em;
  }
  .form-container.promo-form {
    margin-top: 1.5em;
  }
}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {
  .promo-row {
    margin-right: 0em;
  }
  .form-container.promo-form {
    margin-top: 1.5em;
  }
}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {

}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {

}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {

}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {

}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {

}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {

}
  


