.bg-menu {
  position: fixed;
  bottom: 0px;
  right: 0px;
}

.menu-services-bg {
  position: absolute;
  bottom: 0;
  right: 0;
}

.img-bg-services {
  display: block;
}

.img-min-bg-services {
  display: none;
}

.menu-service-content {
  width: 1001px;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.socials-services-container {
  display: flex;
  flex-direction: column;
  margin-right: 5%;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 3;
}

.menu-services-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: center;
  width: 1001px;
}

.myConexctionLink{
  margin: 0px 0px 8px 0px;
  font-size: 1.1em;
  font-weight: 500;
  color: var(--secondary-color);
}

.menu-service-row {
  display: flex;
  flex-direction: row;
}

.menu-services-item {
  display: flex;
  flex-direction: column;
  width: 250px;
  margin: 0 5px 0 5px;
}

.menu__item {
  text-align: center;
  color: #777777;
  border-radius: 20px;
  box-shadow: inset 0 0 0 0 var(--primary-color);
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
}

.container-socials p {
  color: var(--primary-color);
  margin-bottom: 5px;
}
.icons-img {
  width: 35px;
  height: 35px;
  margin: 5px;
  cursor: pointer;
}



.menu__item:hover {
  color: var(--secondary-color);
}

.menu__item.active {
  color: #fff;
}

.menu__item-name,
.menu__item-label {
  position: relative;
  display: inline-block;
}

.menu__item-name {
  font-size: 1.15rem;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.menu__item-label {
  margin: 0 0 0 0.5em;
}

.animGSAP {
  opacity: 0;
}
