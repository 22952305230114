:root {
  --primary-color: #f65911;
  --overlay-primary-color: #a33603;
  --secondary-color: #034380;
  --overlay-secondary-color: #002447;
  --light-color: #dee2e6;
  --gray-color: #777;
  --overlay-gray-color: #4e4e4e;
  --bg-color: #f7f7f8;
  --card-bg-color: #ececef;
  --card-shadow: rgb(15 10 25 / 5%) 0px 30px 40px 0px;
  --text-color: #333333;
  --danger-color: #ff4976;
  --muted-color: #9797a5;
  --success-color:rgb(92, 145, 92);
  --form-input-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
  --button-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  --form-input-focus-outline: 0.5px dashed #f5f5f5;
  --transition-main: all 0.2s ease-in;
  --menu-speed: 0.75s;
  font-size: 16px;
}

#root {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
}

hr {
  display: block;
  color: rgba(0, 0, 0, 0.75);
  border-width: 0.5px;
  width: 100%;
}

/* Colors */
.primary {
  background-color: #f65911;
}

.secondary {
  background-color: #034380;
}

.light {
  background-color: #777;
}

.overlay {
  background-color: #034380ed;
}

.primary-text {
  color: #f65911;
}

.secondary-text {
  color: #034380;
}

.light-text {
  color: #777;
}

.overlay-color-text {
  color: #034380ed;
}

.white-text {
  color: #fff !important;
}

/*  margings */

.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-1 {
  margin-right: 2rem;
  margin-left: 2rem;
}

.mx-2 {
  margin-right: 4rem;
  margin-left: 4rem;
}

/* buttons */

.title {
  color: var(--primary-color);
  font-weight: 900;
}
.sub-title {
  color: var(--secondary-color);
  font-weight: 300;
}

.center-container {
  position: relative;
}
.center-full {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center-vertically {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.center-horizontally {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.page-header {
  height: 4em;
}
.page-section {
  width: 75%;
  height: 80vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.page-section::before {
  background: var(--bg-color);
}
.page-container {
  display: inline-block;
  padding: 4em;
}
.page-title {
  font-size: 2.25em;
  font-weight: 800;
  color: var(--primary-color);
  margin-bottom: 1rem;
}
.page-subtitle {
  font-size: 1.75em;
  font-weight: 800;
  color: var(--secondary-color);
  margin-bottom: 1rem;
}
.page-info {
  height: 425px;
  font-size: 1.25em;
  font-weight: 300;
  color: var(--text-color);
  overflow-y: auto;
}
.img-column {
  position: relative;
  height: 600px;
}
.img-column img {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  width: 100%;
}

/* forms */
/* ------------------------------------------------------ */
.form-container {
  padding: 1.5em;
  background: var(--card-bg-color);
  border-radius: 14px;
  height: 320px;
}
.form-container::-webkit-scrollbar {
  width: 10px;
}
.form-container::-webkit-scrollbar-track {
  background-color: #d9d9eb;
  border-radius: 10px;
}
.form-container::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  box-shadow: inset 0 0 6px var(--overlay-primary-color);
  border-radius: 10px;
}
.form-item {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  height: 75px;
}
.form-item.form-item-txt {
  height: 150px;
}
.form-input-label {
  margin-left: 0.6rem;
  font-weight: 600;
}
.form-input {
  border: 0;
  font-size: 0.85em;
  font-weight: 300;
  padding: 0.5rem 1rem;
  margin: 0.4rem;
  border-radius: 50px;
  -webkit-box-shadow: var(--form-input-shadow);
  -moz-box-shadow: var(--form-input-shadow);
  box-shadow: var(--form-input-shadow);
}
.form-input:focus-visible {
  outline: var(--form-input-focus-outline);
  outline-offset: 4px;
}
.form-item-block {
  padding: 0.5em;
  display: flex;
}
.form-item-button {
  padding: 0.5em;
  margin-left: 10px;
}
.form-item-button p {
  padding: 0px 15px;
}
.form-text {
  font-size: 0.75em;
}
.form-text.text-danger {
  color: var(--danger-color);
}
.form-text.text-muted {
  color: var(--muted-color);
}
.form-footer p {
  padding: 0px 0px 0px 10px;
}
.form-footer p a {
  color: var(--secondary-color);
  font-weight: 500;
}
.form-footer p a:hover {
  color: var(--primary-color);
}
.inputMsg {
  margin: 0px;
  line-height: 0.85em;
  margin-left: 15px;
}
.inputMsg small {
  font-size: 0.75em;
}

.terms-error {
  /* flex-basis: fit-content; */
  margin: 0 0 1rem 1rem;
}

.swal2-styled {
  border-radius: 25px !important;
}
.swal2-styled.swal2-confirm {
  background-color: var(--primary-color) !important;
}


.pop-loader {
  background: transparent !important;
}
.pop-loader-container {
  position: fixed;
  top: 10em;
  background: #dcdce0 !important;
}

/* columns */
.col-col-1 {
  width: 8.33%;
}
.col-col-2 {
  width: 16.66%;
}
.col-col-3 {
  width: 25%;
}
.col-col-4 {
  width: 33.33%;
}
.col-col-5 {
  width: 41.66%;
}
.col-col-6 {
  width: 50%;
}
.col-col-7 {
  width: 58.33%;
}
.col-col-8 {
  width: 66.66%;
}
.col-col-9 {
  width: 75%;
}
.col-col-10 {
  width: 83.33%;
}
.col-col-11 {
  width: 91.66%;
}
.col-col-12 {
  width: 100%;
}

[class*="col-col-"] {
  float: left;
}

/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) {
  .page-section {
    width: 85%;
    overflow: auto;
    margin-bottom: 0px;
    margin-top: 4em;
    padding-bottom: 2em;
  }
  .page-container {
    padding: 0em;
  }
  .page-title {
    font-size: 1.75em;
  }
  .page-info {
    font-size: 1.1em;
  }
  .img-column {
    height: 160px;
  }
  .page-info {
    height: 100%;
    overflow-y: hidden;
  }

  .col-sxs-1 {
    width: 8.33% !important;
  }
  .col-sxs-2 {
    width: 16.66% !important;
  }
  .col-sxs-3 {
    width: 25% !important;
  }
  .col-sxs-4 {
    width: 33.33% !important;
  }
  .col-sxs-5 {
    width: 41.66% !important;
  }
  .col-sxs-6 {
    width: 50% !important;
  }
  .col-sxs-7 {
    width: 58.33% !important;
  }
  .col-sxs-8 {
    width: 66.66% !important;
  }
  .col-sxs-9 {
    width: 75% !important;
  }
  .col-sxs-10 {
    width: 83.33% !important;
  }
  .col-sxs-11 {
    width: 91.66% !important;
  }
  .col-sxs-12 {
    width: 100% !important;
  }
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
  .page-section {
    width: 85%;
    overflow: auto;
    margin-bottom: 0px;
    margin-top: 4em;
    padding-bottom: 2em;
  }
  .page-container {
    padding: 0em;
  }
  .page-title {
    font-size: 1.75em;
  }
  .page-info {
    font-size: 1.1em;
    height: 100%;
    overflow-y: hidden;
  }
  .img-column {
    height: 220px;
  }

  .col-sx-1 {
    width: 8.33% !important;
  }
  .col-sx-2 {
    width: 16.66% !important;
  }
  .col-sx-3 {
    width: 25% !important;
  }
  .col-sx-4 {
    width: 33.33% !important;
  }
  .col-sx-5 {
    width: 41.66% !important;
  }
  .col-sx-6 {
    width: 50% !important;
  }
  .col-sx-7 {
    width: 58.33% !important;
  }
  .col-sx-8 {
    width: 66.66% !important;
  }
  .col-sx-9 {
    width: 75% !important;
  }
  .col-sx-10 {
    width: 83.33% !important;
  }
  .col-sx-11 {
    width: 91.66% !important;
  }
  .col-sx-12 {
    width: 100% !important;
  }
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {
  .page-section {
    width: 85%;
    overflow: auto;
    margin-bottom: 0px;
    margin-top: 4em;
    padding-bottom: 2em;
  }
  .page-container {
    padding: 0em;
  }
  .page-title {
    font-size: 1.75em;
  }
  .page-info {
    font-size: 1.1em;
    height: 100%;
    overflow-y: hidden;
  }
  .img-column {
    height: 350px;
  }

  .col-sm-1 {
    width: 8.33% !important;
  }
  .col-sm-2 {
    width: 16.66% !important;
  }
  .col-sm-3 {
    width: 25% !important;
  }
  .col-sm-4 {
    width: 33.33% !important;
  }
  .col-sm-5 {
    width: 41.66% !important;
  }
  .col-sm-6 {
    width: 50% !important;
  }
  .col-sm-7 {
    width: 58.33% !important;
  }
  .col-sm-8 {
    width: 66.66% !important;
  }
  .col-sm-9 {
    width: 75% !important;
  }
  .col-sm-10 {
    width: 83.33% !important;
  }
  .col-sm-11 {
    width: 91.66% !important;
  }
  .col-sm-12 {
    width: 100% !important;
  }
}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {
  .page-section {
    width: 90%;
    height: 90vh;
    overflow: auto;
    margin-bottom: 0px;
    margin-top: 4em;
    padding-bottom: 2em;
  }
  .page-container {
    padding: 1em;
  }
  .page-title {
    font-size: 1.75em;
  }
  .page-info {
    font-size: 1.1em;
    height: 100%;
    overflow-y: hidden;
  }
  .img-column {
    position: relative;
    height: 400px;
  }

  .col-s-1 {
    width: 8.33% !important;
  }
  .col-s-2 {
    width: 16.66% !important;
  }
  .col-s-3 {
    width: 25% !important;
  }
  .col-s-4 {
    width: 33.33% !important;
  }
  .col-s-5 {
    width: 41.66% !important;
  }
  .col-s-6 {
    width: 50% !important;
  }
  .col-s-7 {
    width: 58.33% !important;
  }
  .col-s-8 {
    width: 66.66% !important;
  }
  .col-s-9 {
    width: 75% !important;
  }
  .col-s-10 {
    width: 83.33% !important;
  }
  .col-s-11 {
    width: 91.66% !important;
  }
  .col-s-12 {
    width: 100% !important;
  }
}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {
  .page-section {
    width: 90%;
    height: 90vh;
    overflow: auto;
    margin-bottom: 0px;
    margin-top: 4em;
    padding-bottom: 2em;
  }
  .page-container {
    padding: 1em;
  }
  .page-title {
    font-size: 1.75em;
  }
  .page-info {
    font-size: 1.1em;
    height: 100%;
    overflow-y: hidden;
  }
  .img-column {
    position: relative;
    height: 500px;
  }

  .col-r-1 {
    width: 8.33% !important;
  }
  .col-r-2 {
    width: 16.66% !important;
  }
  .col-r-3 {
    width: 25% !important;
  }
  .col-r-4 {
    width: 33.33% !important;
  }
  .col-r-5 {
    width: 41.66% !important;
  }
  .col-r-6 {
    width: 50% !important;
  }
  .col-r-7 {
    width: 58.33% !important;
  }
  .col-r-8 {
    width: 66.66% !important;
  }
  .col-r-9 {
    width: 75% !important;
  }
  .col-r-10 {
    width: 83.33% !important;
  }
  .col-r-11 {
    width: 91.66% !important;
  }
  .col-r-12 {
    width: 100% !important;
  }
}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {
  .page-section {
    position: relative;
    width: 90%;
    height: 75vh;
    overflow: auto;
    margin-bottom: 0px;
    margin-top: 4em;
    padding-bottom: 2em;
  }
  .page-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0em;
  }
  .page-title {
    font-size: 2em;
  }
  .page-info {
    font-size: 1.1em;
    height: 400px;
    overflow-y: auto;
  }
  .img-column {
    position: relative;
    height: 500px;
  }

  .col-ipro-1 {
    width: 8.33% !important;
  }
  .col-ipro-2 {
    width: 16.66% !important;
  }
  .col-ipro-3 {
    width: 25% !important;
  }
  .col-ipro-4 {
    width: 33.33% !important;
  }
  .col-ipro-5 {
    width: 41.66% !important;
  }
  .col-ipro-6 {
    width: 50% !important;
  }
  .col-ipro-7 {
    width: 58.33% !important;
  }
  .col-ipro-8 {
    width: 66.66% !important;
  }
  .col-ipro-9 {
    width: 75% !important;
  }
  .col-ipro-10 {
    width: 83.33% !important;
  }
  .col-ipro-11 {
    width: 91.66% !important;
  }
  .col-ipro-12 {
    width: 100% !important;
  }
}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {
  .page-section {
    position: relative;
    width: 90%;
    height: 75vh;
    overflow: auto;
    margin-bottom: 0px;
    margin-top: 4em;
    padding-bottom: 2em;
  }
  .page-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0em;
  }
  .page-title {
    font-size: 2.25em;
  }
  .page-info {
    font-size: 1.15em;
    height: 400px;
    overflow-y: auto;
  }
  .img-column {
    position: relative;
    height: 500px;
  }

  .col-lg-1 {
    width: 8.33% !important;
  }
  .col-lg-2 {
    width: 16.66% !important;
  }
  .col-lg-3 {
    width: 25% !important;
  }
  .col-lg-4 {
    width: 33.33% !important;
  }
  .col-lg-5 {
    width: 41.66% !important;
  }
  .col-lg-6 {
    width: 50% !important;
  }
  .col-lg-7 {
    width: 58.33% !important;
  }
  .col-lg-8 {
    width: 66.66% !important;
  }
  .col-lg-9 {
    width: 75% !important;
  }
  .col-lg-10 {
    width: 83.33% !important;
  }
  .col-lg-11 {
    width: 91.66% !important;
  }
  .col-lg-12 {
    width: 100% !important;
  }
}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {
  .page-section {
    position: relative;
    width: 90%;
    height: 75vh;
    overflow: auto;
    margin-bottom: 0px;
    margin-top: 4em;
    padding-bottom: 2em;
  }
  .page-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0em;
  }
  .page-title {
    font-size: 2.5em;
  }
  .page-info {
    font-size: 1.2em;
    height: 400px;
    overflow-y: auto;
  }
  .img-column {
    position: relative;
    height: 500px;
  }

  .col-xl-1 {
    width: 8.33% !important;
  }
  .col-xl-2 {
    width: 16.66% !important;
  }
  .col-xl-3 {
    width: 25% !important;
  }
  .col-xl-4 {
    width: 33.33% !important;
  }
  .col-xl-5 {
    width: 41.66% !important;
  }
  .col-xl-6 {
    width: 50% !important;
  }
  .col-xl-7 {
    width: 58.33% !important;
  }
  .col-xl-8 {
    width: 66.66% !important;
  }
  .col-xl-9 {
    width: 75% !important;
  }
  .col-xl-10 {
    width: 83.33% !important;
  }
  .col-xl-11 {
    width: 91.66% !important;
  }
  .col-xl-12 {
    width: 100% !important;
  }
}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
  .page-section {
    position: relative;
    width: 90%;
    height: 75vh;
    overflow: auto;
    margin-bottom: 0px;
    margin-top: 4em;
    padding-bottom: 2em;
  }
  .page-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0em;
  }
  .page-title {
    font-size: 2.75em;
  }
  .page-info {
    font-size: 1.25em;
    height: 400px;
    overflow-y: auto;
  }
  .img-column {
    position: relative;
    height: 500px;
  }

  .col-xxl-1 {
    width: 8.33% !important;
  }
  .col-xxl-2 {
    width: 16.66% !important;
  }
  .col-xxl-3 {
    width: 25% !important;
  }
  .col-xxl-4 {
    width: 33.33% !important;
  }
  .col-xxl-5 {
    width: 41.66% !important;
  }
  .col-xxl-6 {
    width: 50% !important;
  }
  .col-xxl-7 {
    width: 58.33% !important;
  }
  .col-xxl-8 {
    width: 66.66% !important;
  }
  .col-xxl-9 {
    width: 75% !important;
  }
  .col-xxl-10 {
    width: 83.33% !important;
  }
  .col-xxl-11 {
    width: 91.66% !important;
  }
  .col-xxl-12 {
    width: 100% !important;
  }
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {
}
