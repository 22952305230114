.character-modal {
  position: absolute !important;
  background-image: url('./images/header-modal.png');
  background-repeat: no-repeat;
  background-size: 16em;
  background-position: -3em -4em;
}
.character-modal .card-body {
  padding-bottom: 2em;
}
.character-modal .character-modal-img-top {
  position: absolute;
  top: -4em;
  left: -2em;
  height: 10em;

}
.close-icon {
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
}
.close-icon img {
  width: 2em;
}
.character-modal-title {
  color: #fff !important;

}
.character-modal-txt {
  padding: 5em 1em 1em 1em;
}

/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) { 
  
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {

}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {

}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {

}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {

}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {

}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {

}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {

}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
    
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {

}