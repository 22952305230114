.menu-mob-container {
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  z-index: 1;
}

.img-mobile-socials {
  margin: 1rem;
}

.icons-mobile-img {
  width: 30px;
  height: 30px;
  margin: 5px;
}

.socials-mobile-services-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.menu-mob-options {
  margin: 0 5%;
}

.menu-mob-row {
  display: flex;
  flex-direction: column;
}

.menu-mob-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
}

.menu-mob-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20rem;
}

.mobile-link {
  margin: 0px;
  padding: 4px 0px;
  border-radius: 50px;
  transition: background 2s;
  transition-timing-function: ease-in;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
}

.mobile-link span {
  font-size: 1.1em;
}

.mobile-link:hover {
  background: #f65911;
  color: rgba(255, 255, 255, 1);
}

.img-mobile-socials p {
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
}

.developed-by p {
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
  font-size: 0.75em;
}
.version-menu p {
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
}

.btn-mobile-menu {
  line-height: 2.2rem;
  font-size: 1.2rem;
  color: rgba(0, 0, 0.75);
}

.btn-mobile-container {
  position: absolute;
  bottom: 38;
  right: 0;
  z-index: 1;
}

.btn-menu-mob-service {
  cursor: pointer;
  background: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid var(--primary-color);
  margin: 8px;
  -webkit-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 7px 0px rgb(0 0 0 / 50%);
}

.btn-mobile-service-txt {
  position: absolute;
  top: 25px;
  font-size: 0.6em;
  font-weight: 500;
  color: var(--primary-color);
}
.btn-mobile-service-img {
  position: absolute;
  top: 28px;
}

/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) {
  .btn-mobile-container {
    bottom: 70px;
    right: 3px;
  }
  .btn-mobile-service-img {
    top: 20px;
  }
  .btn-mobile-service-txt {
    top: 18px;
    font-size: 0.5em;
    font-weight: 500;
  }
  .btn-mobile-menu {
    font-size: 0.9em;
  }
  .btn-menu-mob-service {
    width: 45px;
    height: 45px;
    margin: 4px;
    border: 3px solid #f65911;
  }  
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
  .btn-mobile-container {
    bottom: 70px;
    right: 3px;
  }

  .btn-mobile-service-img {
    top: 20px;
  }
  .btn-mobile-service-txt {
    top: 18px;
    font-size: 0.5em;
    font-weight: 500;
  }
  .btn-mobile-menu {
    font-size: 0.9em;
  }
  .btn-menu-mob-service {
    width: 45px;
    height: 45px;
    margin: 4px;
    border: 3px solid #f65911;
  }  
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {
  .btn-mobile-container {
    bottom: 70px;
    right: 3px;
  }
}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {
  .btn-mobile-container {
    bottom: 70px;
    right: 3px;
  }
}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {
  .btn-mobile-container {
    bottom: 70px;
    right: 3px;
  }
}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {
  .btn-mobile-container {
    bottom: 70px;
    right: 3px;
  }
}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {
  .btn-mobile-container {
    bottom: 70px;
    right: 3px;
  }
}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {
  .btn-mobile-container {
    bottom: 70px;
    right: 3px;
  }
}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
  .btn-mobile-container {
    bottom: 70px;
    right: 3px;
  }
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {
  .btn-mobile-container {
    bottom: 70px;
    right: 3px;
  }
}
