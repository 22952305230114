.politics-container {
  width: 90%;
}

.politics-content b {
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  color: var(--secondary-color);
  font-weight: 800;
  font-size: 1rem;
}

.politics-content {
  margin-left: 0.6rem;
  margin-right: 0.6rem;
  color: rgba(0, 0, 0, 0.75);
  font-weight: 300;
  font-size: 1rem;
}
