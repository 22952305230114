.form-container {
  margin-top: 1.25em;
}

.page-content {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
}
.contact-bg {
  background: url('../../../../public/images/background-4.png') no-repeat center center/cover;
}

/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) { 
  .img-column.contact-img {
    height: 320px;
  }
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
  .img-column.contact-img {
    height: 320px;
  }
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {
  .img-column.contact-img {
    height: 450px;
  }
}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {
  .img-column.contact-img {
    height: 450px;
  }
}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {
  .img-column.contact-img img {
    
  }
}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {
  .img-column.contact-img {
    height: 600px;
  }
}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {
  .img-column.contact-img img {
    
  }
}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {
  .img-column.contact-img img {
    
  }
}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
  .img-column.contact-img img {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {

}







/* .page-contact {
    padding: 3%;
    background: #ffffff;
}

.page-contact-container {
  margin-top: 80px;
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
  justify-content: center;
}

.page-contact-item {
    align-self: center;
}

.pages-contact-content {
  width: 666px;
}

.pages-contact-image {
  width: 666px;
}

.page-contact-title {
  font-size: 1.8rem;
  font-weight: 800;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.page-contact-info {
  font-size: 1.2rem;
  font-weight: 300;
  color: var(--secondary-color);
}

@media (min-width: 500px) and (max-width: 750px) {
  .pages-contact-content {
    width: 450px;
  }
  .pages-contact-image {
    width: 450px;
  }
}

@media (min-width: 350px) and (max-width: 499px) {
  .pages-contact-content {
    width: 340px;
  }
  .pages-contact-image {
    width: 340px;
  }
}

@media (min-width: 250px) and (max-width: 349px) {
  .pages-contact-content {
    width: 240px;
  }
  .pages-contact-image {
    width: 240px;
  }
} */
