.character-modal-container {
  position: absolute;
  top: 5rem;
  right: 2em;
  width: 80%;
  border-radius: 14px;
  background: #ffffff;
  box-shadow: var(--card-shadow);
  z-index: 1;
}
.modal-container {
  border-radius: 14px;
  background-image: url('../../public/images/header-modal.png');
  background-repeat: no-repeat;
  background-size: 15em;
  background-position: -2em 0em;
  padding: 1em;
}
.close-icon-character {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  z-index: 60;
}
.modal-image {
  position: absolute;
  top: 0;
  left: 2em;
}
.title-character-modal {
  position: absolute;
  left: 10%;
  top: 8%;
  width: 240px;
}
.close-icon-character img {
  width: 100%;
  height: 100%;
}

.character-modal-picture-container {
  position: relative;
  width: 100%;
}
.caracter-modal-info {
  max-height: 180px;
  overflow-y: auto;
}
.character-modal-picture {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}




/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) { 
  .modal-container {
    background-size: 13.5em;
    background-position: -2em 0em;
  }
  .character-modal-row {
    margin-top: 5em;
  }
  .character-modal-picture {
    width: auto;
    height: 220px;
  }
  .caracter-modal-info {
    margin-top: 13em;
    font-size: 0.9em;
  }
  .character-modal-picture-container {
    top: -24.5em;
  }
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
  .modal-container {
    background-size: 13.5em;
    background-position: -2em 0em;
  }
  .character-modal-row {
    margin-top: 5em;
  }
  .character-modal-container {
    right: 1em;
  }
  .character-modal-picture {
    width: auto;
    height: 220px;
  }
  .caracter-modal-info {
    margin-top: 14em;
    font-size: 0.9em;
  }
  .character-modal-picture-container {
    top: -22.5em;
  }
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {
  .modal-container {
    background-size: 13.5em;
    background-position: -2em 0em;
  }
  .character-modal-row {
    margin-top: 5em;
  }
  .character-modal-container {
    right: 1em;
  }
  .character-modal-picture-container {
    top: -4em;
  }
  .character-modal-picture {
    width: auto;
    height: 315px;
  }
  .caracter-modal-info {
    margin-top: 2em;
    font-size: 0.9em;
  }
}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {
  .modal-container {
    background-size: 13.5em;
    background-position: -2em 0em;
  }
  .character-modal-row {
    margin-top: 5em;
  }
  .character-modal-container {
    right: 1em;
  }
  .character-modal-picture-container {
    top: -6em;
  }
  .character-modal-picture {
    width: auto;
    height: 250px;
  }
  .caracter-modal-info {
    margin-top: 2em;
    font-size: 0.9em;
  }
}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {
  .modal-container {
    background-size: 13.5em;
    background-position: -2em 0em;
    min-height: 15em;
  }
  .character-modal-row {
    margin-top: 5em;
  }
  .character-modal-container {
    right: 1em;
  }
  .character-modal-picture-container {
    top: -6em;
  }
  .character-modal-picture {
    width: auto;
    height: 280px;
  }
  .caracter-modal-info {
    margin-top: 2em;
    font-size: 0.9em;
  }
}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {
  .modal-container {
    background-size: 13.5em;
    background-position: -2em 0em;
    min-height: 18em;
  }
  .character-modal-row {
    margin-top: 5em;
  }
  .character-modal-container {
    right: 1em;
    width: 60%;
  }
  .character-modal-picture-container {
    top: -6em;
  }
  .character-modal-picture {
    width: auto;
    height: 330px;
  }
  .caracter-modal-info {
    margin-top: 2em;
    font-size: 0.9em;
  }
}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {
  .modal-container {
    background-size: 13.5em;
    background-position: -2em 0em;
    min-height: 18em;
  }
  .character-modal-row {
    margin-top: 5em;
  }
  .character-modal-container {
    right: 1em;
    width: 60%;
  }
  .character-modal-picture-container {
    top: -6em;
  }
  .character-modal-picture {
    width: auto;
    height: 330px;
  }
  .caracter-modal-info {
    margin-top: 2em;
    font-size: 0.9em;
  }
}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {
  .modal-container {
    background-size: 13.5em;
    background-position: -2em 0em;
    min-height: 18em;
  }
  .character-modal-row {
    margin-top: 5em;
  }
  .character-modal-container {
    right: 1em;
    width: 60%;
  }
  .character-modal-picture-container {
    top: -6em;
  }
  .character-modal-picture {
    width: auto;
    height: 330px;
  }
  .caracter-modal-info {
    margin-top: 2em;
    font-size: 0.9em;
  }
}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
  .modal-container {
    background-size: 13.5em;
    background-position: -2em 0em;
    min-height: 18em;
  }
  .character-modal-row {
    margin-top: 5em;
  }
  .character-modal-container {
    right: 1em;
    width: 60%;
  }
  .character-modal-picture-container {
    top: -6em;
  }
  .character-modal-picture {
    width: auto;
    height: 330px;
  }
  .caracter-modal-info {
    margin-top: 2em;
    font-size: 0.9em;
  }
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {
  .modal-container {
    background-size: 13.5em;
    background-position: -2em 0em;
    min-height: 18em;
  }
  .character-modal-row {
    margin-top: 5em;
  }
  .character-modal-container {
    right: 1em;
    width: 60%;
  }
  .character-modal-picture-container {
    top: -6em;
  }
  .character-modal-picture {
    width: auto;
    height: 330px;
  }
  .caracter-modal-info {
    margin-top: 2em;
    font-size: 0.9em;
  }
}
