.container-gift {
	position: absolute;
	top: 5px;
	right: 5px;
	width: 60px;
	height: 60px;
	background-color: white;
	border: 5px solid var(--secondary-color);
	border-radius: 50%;
	cursor: pointer;
}

.img-gift {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 35px;
}


/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) { 
  .container-gift {
		width: 45px;
		height: 45px;
		border: 3px solid var(--secondary-color) !important;
	}
	.img-gift {
		width: 30px;
	}
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
	.container-gift {
		width: 45px;
		height: 45px;
		border: 3px solid var(--secondary-color) !important;
	}
	.img-gift {
		width: 30px;
	}
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {

}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {

}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {

}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {

}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {

}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {

}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
    
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {

}