.menu-main-container {
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
}

.menu-mobile-content-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.menu-mobile-content-container .menu-mobile-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img-mobile-socials {
  margin: 1rem;
}

.img-mobile-socials p {
  margin-top: 0px;
  text-align: center;
}

.icons-mobile-img {
  width: 30px;
  height: 30px;
  margin: 5px;
}

.socials-mobile-services-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.menu-mobile-options {
  margin: 0 5%;
}

.menu-mobile-row {
  display: flex;
  flex-direction: column;
  font-weight: 800;
}

.title-main-menu {
  font-size: 1.5rem;
  line-height: 3.2rem;
  text-align: center;
  cursor: pointer;
}

.menu-arrow {
  position: absolute;
  right: 5px;
}

.menu-mobile-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
}

.mobile-link {
  margin: 7px 0 7px 0;
  padding: 2px 16px;
  border-radius: 50px;
  transition: background 2s;
  transition-timing-function: ease-in;
  width: 100%;
}

.mobile-link:hover {
  background: #f65911;
  color: rgba(255, 255, 255, 1);
}

.btn-mobile-menu {
  line-height: 2.2rem;
  font-size: 1.2rem;
  color: rgba(0, 0, 0.75);
}

.btn-main-container {
  position: absolute;
  top: 0;
  left: 0;
  
}

.btn-menu-mobile-service {
  cursor: pointer;
  background: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #f65911;
  margin: 8px;
  -webkit-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 7px 0px rgb(0 0 0 / 50%);
}

.btn-menu-mobile-service img {
  width: 40px;
  height: auto;
}

.btn-menu-mobile-service.btn-close img {
  width: 35px;
  height: auto;
}

.fix-menu-text-bold{
  font-weight: 800;
}

.services-main-drop {
  height: 0px;
  overflow-y: hidden;
}

.services-about-drop{
  height: 0px;
  overflow-y: hidden;
}

.services-help-drop {
  height: 0px;
  overflow-y: hidden;
}

.drop-menu-mobile {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}

.developed-by p {
  text-align: center;
  font-size: 0.85em;
}

.version-menu p {
  text-align: center;
}









/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) { 
  .btn-menu-mobile-service {
    width: 45px;
    height: 45px;
    margin: 4px;
    border: 3px solid #f65911;
  }
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
  .btn-menu-mobile-service {
    width: 45px;
    height: 45px;
    margin: 4px;
    border: 3px solid #f65911;
  }
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {

}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {

}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {

}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {

}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {

}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {

}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
    
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {

}