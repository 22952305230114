.img-characters {
  position: relative;
  height: 100%;
}
.img-characters img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.about-socials {
  margin-top: 2em;
}
.about-us {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
  background: url('../../../../public/images/background-1.png') no-repeat center center/cover;
}


/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) { 
  
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {

}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {

}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {

}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {

}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {

}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {
  .about-socials {
    top: -10em;
  }
}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {
  .about-socials {
    top: -10em;
  }
}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
  .about-socials {
    top: -10em;
  }
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {

}