.page-list {
  padding: 3%;
}

.page-list-item {
  align-self: center;
}

.page-list-title {
  font-size: 1.8rem;
  font-weight: 800;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.page-list-info {
  font-size: 1.2rem;
  font-weight: 300;
  color: var(--secondary-color);
}

.page-list-slogan {
  font-size: 1.5em;
}

.page-content {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
}
.how {
  background: url('../../../../public/images/background-1.png') no-repeat center center/cover;
}


/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) { 
  .page-list-item {
    font-size: 0.9em;
  }
  .img-column.page-list {
    height: 275px;
  }
  .img-column.page-list img {
    top: unset;
    left: 50%;
    width: auto;
    height: 275px;
    transform: translateX(-50%);
  }
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
  .img-column.page-list {
    height: 275px;
  }
  .img-column.page-list img {
    top: unset;
    left: 50%;
    width: auto;
    height: 275px;
    transform: translateX(-50%);
  }
  .page-list-item {
    font-size: 0.9em;
  }
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {
  .page-container {
    padding-top: 2em;
  }
  .img-column.page-list {
    height: 650px;
  }
  .img-column.page-list img {
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 650px;
  }
}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {
  .img-column.page-list img {
    width: auto;
    height: 400px;
  }
}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {

}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {
  .page-section {
    margin-top: 3em;
  }
  .img-column.page-list img {
    width: auto;
    height: 500px;
  }
}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {
  .page-section {
    margin-top: 3em;
  }
  .img-column.page-list img {
    width: auto;
    height: 500px;
  }
}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {
  .page-section {
    margin-top: 3em;
  }
  .img-column.page-list img {
    width: auto;
    height: 500px;
  }
}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
  .page-section {
    margin-top: 3em;
  }
  .img-column.page-list img {
    width: auto;
    height: 500px;
  }
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {

}
