/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700;900&display=swap'); */

@font-face {
  font-family: 'Montserrat';
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700;900&display=swap');
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--gray-color);
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700 !important;
}

h1, h2 {
  color: var(--primary-color) !important;
}

h3, h4, h5, h6 {
  color: var(--secondary-color) !important;
}

p {
  color: var(--gray-color);
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 300;
  word-wrap: break-word;
}

a {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 300;
  text-decoration: none; 
}

.form-group {
  margin-bottom: 0.5em !important;
}
.round-control {
  border-radius: 4em !important;
}
.form-control:focus {
  color: var(--secondary-color);
  background-color: #fff;
  border-color: var(--light-color);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--light-color);
}
.form-group label {
  color: var(--secondary-color);
}

::-webkit-input-placeholder { /* Edge */
  color: var(--gray-color);
}

:-ms-input-placeholder { /* Internet Explorer */
  color: var(--gray-color);
}

::placeholder {
  color: var(--gray-color);
}

.btn.btn-custom {
  padding: 0.75em 2.75em !important;
  border-radius: 4em !important;
  -webkit-box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%), 0 1px 1px rgb(0 0 0 / 5%) !important;
  -moz-box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%), 0 1px 1px rgb(0 0 0 / 5%) !important;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 10%), 0 1px 1px rgb(0 0 0 / 5%) !important;
  cursor: pointer;
}
.btn.btn-custom.btn-main {
  background: var(--secondary-color) !important;
  color: #fff !important;
  transition: all 0.2s ease-in;
}
.btn.btn-custom.btn-main:hover {
  background: var(--overlay-secondary-color) !important;
  color: #fff !important;
}
.btn.btn-custom.btn-sec {
  background: var(--primary-color) !important;
  color: #fff !important;
  transition: all 0.2s ease-in;
}
.btn.btn-custom.btn-sec:hover {
  background: var(--overlay-primary-color) !important;
  color: #fff !important;
}
.btn.btn-custom.btn-third {
  background: var(--light-color) !important;
  color: var(--gray-color) !important;
  transition: all 0.2s ease-in;
}
.btn.btn-custom.btn-third:hover {
  background: var(--overlay-gray-color) !important;
  color: #fff !important;
}


/* think
----------------------------------------------------------------------- */
.think-link {
  color: #00aaff !important;
  -webkit-transition: all 0.2s ease-in-out 0s !important;
  -o-transition: all 0.2s ease-in-out 0s !important;
  transition: all 0.2s ease-in-out 0s !important;
}
.think-link:hover,
.think-link:active,
.think-link:focus {
  color: #ffffff !important;
}
.think-link.dark:hover,
.think-link.dark:active,
.think-link.dark:focus {
  color: #1d2127 !important;
}

.App{
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
  background-color: var(----bg-color);
}

.main-constuction{
  background-color: aquamarine;
}

section .section-title .title {
  color: var(--primary-color);
  font-weight: 900;
}
section .section-title .sub-title {
  color: var(--secondary-color);
  font-weight: 300;
}

.inner-page .main-section-inner {
  width: 100vh;
  height: 100vh;
  margin-top: 10em;
  margin-bottom: 10em;
}

.character-space-container {
  position: relative;
  display: table;
  height: 100%;
}
.character-space-container .character-space {
  display: table-cell;
  vertical-align: middle;
}

.showcase{
  color: #fff;
  height: 100vh;
  position: relative;
}
.showcase:before{
  content: '';
  background: url('https://images.pexels.com/photos/37839/pexels-photo-37839.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260') no-repeat center center/cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}
.showcase .showcase-inner{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}
.showcase h1{
  font-size: 4rem;
}
.showcase p{
  font-size: 1.3rem;
  font-family: 'Oswald', sans-serif;
}
.btn{
  display: inline-block;
  border: none;
  background-color: var(--primary-color);
  color: #fff;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  transition: opacity 1s ease-in;
  border-radius: 2rem;
}
.btn:hover{
  background: var(--primary-color);
}
.contact-links{
  display: flex;
  justify-content: center;
  width: 200px;
  max-width: 980px;
  flex-wrap: wrap;
  margin-left: 54.5rem; 
  margin-top: -12rem;
}
.contact-details{
  font-size: 1rem;
  margin-bottom: 1rem;
  transition: transform 0.3s ease-out; 
}
.contact-details:hover{
  transform: translateY(5px);
}
.btn{
  display: inline-block;
  padding-right: 1rem;
  text-decoration: none;
  color: #fff;
}
.menu-wrap{
  position: fixed;
  top: 0;
  left: 0;

}
.menu-wrap .toggler{
  position: absolute;
  top: 0;
  left: 0;

  cursor: pointer;
  width: 50px;
  height: 50px;
  opacity: 0;
}
.menu-wrap .hamburger{
  position: absolute;
  top: 0;
  left: 0;

  width: 60px;
  height: 60px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Hamburger line */
.menu-wrap .hamburger >div {
  position: relative;
  width: 100%;
  height: 2px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}
/* Top and bottom lines */
.menu-wrap .hamburger > div:before,
.menu-wrap .hamburger > div:after{
  content: '';
  position: absolute;

  top: -10px;
  width: 100%;
  height: 2px;
  background: inherit;
}
/* Moves line down */
.menu-wrap .hamburger > div:after{
  top: 10px;
}
/* Toggler animate */
.menu-wrap .toggler:checked + .hamburger > div{
  transform: rotate(135deg);
}
/* Turn Lines into X */
.menu-wrap .toggler:checked + .hamburger > div:before,
.menu-wrap .toggler:checked + .hamburger > div:after{
  top: 0;
  transform: rotate(90deg);
}
/* Rotate on hover when checked */
.menu-wrap .toggler:checked:hover + .hamburger > div{
  transform: rotate(225deg);
}
/* Show menu */
.menu-wrap .toggler:checked ~ .menu{
  visibility: visible;
}
.menu-wrap .toggler:checked ~ .menu > div{
  transform: scale(1);
  transition-duration: var(--menu-speed);
}
.menu-wrap .toggler:checked ~ .menu > div > div{
  opacity: 1;
  transition: opacity 0.4s ease;
}
.menu-wrap .menu{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
   visibility: hidden; 
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-wrap .menu > div{
  background-color: var(--overlay-secondary-color);
  border-radius: 50%;
  width: 200vw;
  height: 200vw;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
   transform: scale(0); 
  transition: all 0.4s ease;
}
.menu-wrap .menu > div >div{
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
   opacity: 0; 
  transition: opacity 0.4s ease;
}
.menu-wrap .menu > div > div > ul > li {
  list-style: none;
  color: #fff;
  font-size: 1.5rem;
  padding: 1rem;
}
.menu-wrap .menu >div > div > ul > li > a{
  color: inherit;
  text-decoration: none;
  transition: color 0.4s ease;
}

.section-inner-page {
  position: relative;
  height: 100vh;
}

.section-inner-page .container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



/* 
.container-back-home {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 50px;
  height: 50px;
  background-color: var(--secondary-color);
  border: 4px solid var(--primary-color);
  border-color: var(--secondary-color) var(--primary-color) var(--secondary-color) var(--secondary-color);
  border-radius: 0 0 100% 0;
  cursor: pointer;
}

.img-back {
  position: relative;
  top: 3px;
  left: -2px;
  width: 35px;
} */