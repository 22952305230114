.gift-page {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
  background: url('../../../public/images/background-4.png') no-repeat center center/cover;
}

.gift-container {
  background-color: #ffffff;
  display: flex;
  position: absolute;
  width: 100%;
  height: -webkit-fill-available;
  justify-content: center;
  align-items: center;
}

.gift-nav {
  position: fixed;
  z-index: 1;
  top:0;
  left: 0;
  height: 100px;
}

.gift-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gift-content-forms {
  width: 40%;
  height: fit-content;
}

.gift-content-promos {
  width: 50%;
  height: fit-content;
  padding: 1em;
}

.section-title {
  margin-left: 2em;
}


@media (min-width: 0px) and (max-width: 700px) {
  /* .gift-container {
    padding: 1%;
    height: auto;
  }

  .gift-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
  }

  .gift-content-forms {
    width: 90%;
    height: fit-content;
    margin-bottom:5rem ;
  }
  .section-title {
    margin-top: 7rem;
    margin-left: 0;
  } */
}



/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) { 
  .gift-container {
    padding: 1%;
    height: auto;
  }
  .gift-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
  }
  .gift-content-forms {
    width: 100%;
    height: fit-content;
    margin-bottom:5rem ;
  }
  .gift-content-promos {
    width: 100%;
  }
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
  .gift-container {
    padding: 1%;
    height: auto;
  }
  .gift-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
  }
  .gift-content-forms {
    width: 100%;
    height: fit-content;
    margin-bottom:5rem ;
  }
  .gift-content-promos {
    width: 100%;
  }
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {
  .gift-container {
    padding: 1%;
    height: auto;
  }
  .gift-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
  }
  .gift-content-forms {
    width: 100%;
    height: fit-content;
    margin-bottom:5rem ;
  }
  .gift-content-promos {
    width: 100%;
  }
}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {
  .gift-content-forms {
    padding-right: 3em;
  }
}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {

}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {

}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {

}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {

}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
    
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {

}