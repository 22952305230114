.img-family img {
  width: 100%;
}
.character-img img {
  width: 100%;
}

.family-members {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.container-family {
  padding: 1em;
}

.character-img {
  background: #ffffff;
  border: solid 5px #F65911;
  border-radius: 50%;
}

.character-details-container {
  height: 100%;
  position: relative;
}

.character-details {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}