.three-loader {
  position: absolute;
  top: 0px;
  background-color: var(--bg-color);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.three-loader-container {
  width: 60%;
}

.three-loader-content {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.three-loader-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 550px;
  padding: 2rem;
}

.three-loader-image {
  margin-top: 30px;
  max-width: 650px;
}

.three-loader-title {
  text-align: center;
  font-weight: 800;
  color: rgba(0, 0, 0, 0.7);
  font-size: 1.2rem;
}

.three-loader-text {
  max-width: 440px;
  padding: 2rem;
  margin-top: 0px;
  padding-top: 0px;
  text-align: center;
  font-size: 1.2rem;
}

.three-loader-market-place-title {
  text-align: center;
  margin: 0px;
  font-weight: 700;
  color: var(--primary-color);
}


.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid var(--primary-color);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) {
  .three-loader-image {
    max-width: 250px;
  }
  .three-loader {
    width: auto;
  }
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
  .three-loader-container {
    width: 98%;
  }
  .three-loader-image {
    max-width: 318px;
  }
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {
  .three-loader-container {
    width: 90%;
  }

  .three-loader-image {
    max-width: 500px;
  }
}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {
  .three-loader-image {
    max-width: 750px;
  }
}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {
}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {
}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {
}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {
}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {
}
