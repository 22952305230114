.audioBtn {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 55px;
  height: 55px;
  border-radius: 0 0 50% 50%;
  border-top: none;
  border-left: 4px solid var(--primary-color);
  border-right: 4px solid var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  -webkit-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 7px 0px rgb(0 0 0 / 50%);
  cursor: pointer;
}
.audioBtn img {
  width: 30px;
  height: 30px;
  margin-top: -5px;
}

/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) { 
  .audioBtn {
    width: 55px;
    height: 55px;
    top: -12px;
  }
  .audioBtn img {
    width: 30px;
    height: 30px;
    margin-top: 7px;
  }
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
  .audioBtn {
    width: 55px;
    height: 55px;
    top: -12px;
  }
  .audioBtn img {
    width: 30px;
    height: 30px;
    margin-top: 7px;
  }
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {

}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {

}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {

}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {

}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {

}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {

}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
    
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {

}