.cookies {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 30%;
  margin: auto;
  padding: 20px 20px 0px 20px;
  background: rgba(255, 255, 255, .87);
  border-radius: 30px 30px 0 0;
  z-index: 2;
}
.cookies-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.cookies-info {
  display: block;
  align-content: flex-start;
  align-items: center;
  text-align: center;
}

.cookies-info-text {
  font-size: 0.85em;
}

.cookies-btn {
  color: #ffffff;
  font-size: 0.85em;
  font-weight: 300;
  padding: 0.4rem 2.3rem;
  border: none;
  border-radius: 50px;
  margin: 0em 1em 1em 1em;
  cursor: pointer;
}

.cookies-btn.accept {
  background: var(--secondary-color);
}

.cookies-btn.decline {
  background: var(--danger-color);
}


.cookies-warning {
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 800;
  margin: 0 0 1.5rem 0;
}


/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) { 
  .cookies {
    max-width: 50%;
    padding-bottom: 1em !important;
  }
  .cookies-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
  .cookies {
    max-width: 50%;
    padding-bottom: 1em !important;
  }
  .cookies-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {
  .cookies {
    max-width: 60%;
    padding-bottom: 1em !important;
  }
  .cookies-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {

}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {

}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {

}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {

}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {

}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
    
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {

}