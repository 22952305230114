.circular-menu {
  bottom: 27%;
  left: 15px;
}

.btn-menu-help {
  position: absolute;
  bottom: 245px;
  left: 5px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #f9f9fc;
  border: 5px solid #034380;
  -webkit-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 7px 0px rgb(0 0 0 / 50%);
  cursor: pointer;
  z-index: 1;
}
.icon-contact {
  width: 40px;
  height: 40px;
}

.btn-menu-help.btn-menu-help-open {
  bottom: 330px;
  width: 75px;
  height: 75px;
  border: 4px solid #034380;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  z-index: 30;
}
.btn-menu-help.btn-menu-help-open .icon-contact {
  width: 40px;
  height: 40px;
}

.menu-button-circle {
  position: fixed;
  bottom: 3.5em;
  left: -8em;
  width: 30em;
  height: 30em;
  background: #f9f9fc;
  border-radius: 50%;
  transition: 500ms ease;
  -webkit-box-shadow: 4px 4px 11px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 4px 11px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 6px 6px 12px 1px rgba(0, 0, 0, 0.75);
  z-index: 20;
}

.menu-button {
  position: absolute;
  bottom: 255px;
  left: 20px;
  transform: rotate(270deg);
  cursor: pointer;
  z-index: 30;
}

.menu-button-circle.circle-hidden {
  display: none;
}

.menu-item {
  position: absolute;
  z-index: 1;
}

.menu-item {
  position: absolute;
  transition: 500ms ease;
}

.menuy-item .initial-btn {
  height: 75px;
  width: 75px;
  background-color: #ffffff;
  border: 4px solid var(--secondary-color);
  border-radius: 50%;
}

.menu-item .initial-btn img {
  position: absolute;
  top: 16px;
  left: 18px;
  width: 40px;
  height: 40px;
  transform: rotate(90deg);
}

.menu-item .menu-item-btn {
  height: 75px;
  width: 75px;
  background-color: #ffffff;
  border: 4px solid var(--secondary-color);
  border-radius: 50%;
}

.menu-item .menu-item-btn img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%) rotate(90deg);
}

.menu-item.menu-close {
  border-color: transparent;
  background: transparent;
}
.menu-item.menu-close img,
.menu-item.menu-close .menu-item-description {
  display: none;
}

.menu-item .menu-item-btn .menu-item-description {
  position: absolute;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.25em;
  width: 90px;
}

.menu-item.menu-open.menu-item-1 {
  left: 30px;
  transform: rotate(0deg) translate(175%);
  transition-delay: 0ms;
}
.menu-item.menu-open.menu-item-1 img {
  transform: translate(-50%, -50%) rotate(90deg);
}
.menu-item.menu-open.menu-item-1 .menu-item-description {
  top: 116px;
  left: -4px;
  transform: rotate(90deg);
}

.menu-item.menu-open.menu-item-2 {
  transform: rotate(45deg) translate(175%);
  transition-delay: 7ms;
}
.menu-item.menu-open.menu-item-2 img {
  transform: translate(-50%, -50%) rotate(45deg);
}
.menu-item.menu-open.menu-item-2 .menu-item-description {
  top: 90px;
  left: 60px;
  transform: rotate(45deg);
}

.menu-item.menu-open.menu-item-3 {
  top: 4px;
  transform: rotate(90deg) translate(175%);
  transition-delay: 14ms;
}
.menu-item.menu-open.menu-item-3 img {
  transform: translate(-50%, -50%) rotate(1deg);
}
.menu-item.menu-open.menu-item-3 .menu-item-description {
  top: 25px;
  left: 90px;
  transform: rotate(1deg);
}

.menu-item.menu-open.menu-item-4 {
  transform: rotate(135deg) translate(175%);
  transition-delay: 21ms;
}
.menu-item.menu-open.menu-item-4 img {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.menu-item.menu-open.menu-item-4 .menu-item-description {
  top: -32px;
  left: 64px;
  transform: rotate(-45deg);
}

.menu-item.menu-open.menu-item-5 {
  left: -30px;
  transform: rotate(180deg) translate(175%);
  transition-delay: 28ms;
}
.menu-item.menu-open.menu-item-5 img {
  transform: translate(-50%, -50%) rotate(270deg);
}
.menu-item.menu-open.menu-item-5 .menu-item-description {
  transform: rotate(270deg);
  top: -75px;
  left: 0px;
}

/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) {
  .btn-menu-help {
    width: 45px !important;
    height: 45px !important;
    bottom: 320px;
    border: 3px solid var(--secondary-color) !important;
  }
  .icon-contact {
    width: 35px;
    height: 35px;
  }
  .menu-item .menu-item-btn {
    height: 50px;
    width: 50px;
    border: 3px solid var(--secondary-color);
  }
  .menu-item .menu-item-btn img {
    width: 35px;
    height: 35px;
  }
  .menu-button-circle {
    bottom: 5em;
    left: -6em;
    width: 25em;
    height: 25em;
  }
  .menu-item .menu-item-btn .menu-item-description {
    font-size: 0.85em;
    width: 120px;
  }
  .menu-item.menu-open.menu-item-1 .menu-item-description {
    top: 120px;
    left: -30px;
  }
  .menu-item.menu-open.menu-item-2 .menu-item-description {
    top: 80px;
    left: 40px;
  }
  .menu-item.menu-open.menu-item-3 .menu-item-description {
    top: 15px;
    left: 70px;
  }
  .menu-item.menu-open.menu-item-4 .menu-item-description {
    top: -45px;
    left: 40px;
  }
  .menu-item.menu-open.menu-item-5 .menu-item-description {
    top: -90px;
    left: -30px;
  }
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
  .btn-menu-help {
    width: 45px !important;
    height: 45px !important;
    bottom: 320px;
    border: 3px solid var(--secondary-color) !important;
  }
  .icon-contact {
    width: 35px;
    height: 35px;
  }
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {
  .btn-menu-help {
    bottom: 320px;
  }
}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {
  .btn-menu-help {
    bottom: 295px;
  }
}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {
  .btn-menu-help {
    bottom: 295px;
  }
}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {
  .btn-menu-help {
    bottom: 320px;
  }
}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {
}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {
}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {
}
