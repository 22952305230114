.container-back-home {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 60px;
  height: 60px;
  background-color: var(--secondary-color);
  border: 4px solid var(--primary-color);
  border-color: var(--secondary-color) var(--primary-color) var(--secondary-color) var(--secondary-color);
  border-radius: 0 0 100% 0;
  cursor: pointer;
}

.img-back {
  position: relative;
  top: 1px;
  width: 40px;
}


/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) { 
  .container-back-home {
    width: 50px;
    height: 50px;
  }
  .img-back {
    width: 30px;
    top: 5px;
    left: 2px;
  }
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
  .container-back-home {
    width: 50px;
    height: 50px;
  }
  .img-back {
    width: 30px;
    top: 5px;
    left: 2px;
  }
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {
  .container-back-home {
    width: 50px;
    height: 50px;
  }
  .img-back {
    width: 30px;
    top: 5px;
    left: 2px;
  }
}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {
  .container-back-home {
    width: 50px;
    height: 50px;
  }
  .img-back {
    width: 30px;
    top: 5px;
    left: 2px;
  }
}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {

}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {

}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {

}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {

}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
    
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {

}