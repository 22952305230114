.family-img {
  position: unset;
  top: unset;
  left: unset;
  transform: unset;
  height: 300px;
}
.family-img img {
  position: unset;
  top: unset;
  left: unset;
  transform: unset;
  width: 100%;
}
.card-character-container {
  height: 600px;
  overflow-y: auto;
}
.character-card {
  position: relative;
  display: inline-flex;
  margin: 0.5rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 80px;
}
.character-card-image {
  width: 120px;
  height: 120px;
  margin-top: 0.75em;
}
.character-card-info {
  padding: 1em;
}
.character-card-name {
  color: var(--secondary-color);
  font-size: 1.6rem;
  font-weight: 600;
}
.character-card-bio {
  font-family: Montserrat, sans-serif !important;
  font-weight: 300;
  color: var(--gray-color);
}
.character-picture {
  width: 100px;
  height: 100px;
  background: #ffffff;
  border-radius: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: var(--primary-color);
}

.page-content {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
}
.williams {
  background: url('../../../../public/images/background-2.png') no-repeat center center/cover;
}
.garcia {
  background: url('../../../../public/images/background-3.png') no-repeat center center/cover;
}


/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) { 
  .family-img {
    height: 100%;
  }
  .card-character-container {
    height: 100%;
    overflow-y: hidden;
  }
  .character-card {
    border-radius: 14px;
  }
  .character-picture {
    width: 5em;
    height: 5em;
  }
  .character-data-container {
    margin-left: 0em;
    margin-top: 0em;
  }
  .character-card-bio {
    font-size: 0.9em;
  }
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
  .family-img {
    height: 100%;
  }
  .card-character-container {
    height: 100%;
    overflow-y: hidden;
  }
  .character-card {
    border-radius: 14px;
  }
  .character-picture {
    width: 6em;
    height: 6em;
  }
  .character-data-container {
    margin-left: 0em;
    margin-top: 0em;
  }
  .character-card-bio {
    font-size: 0.85em;
  }
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {
  .family-img {
    height: 100%;
  }
  .card-character-container {
    height: 100%;
    overflow-y: hidden;
  }
  .character-picture {
    width: 5em;
    height: 5em;
    margin-top: 10px;
  }
  .character-data-container {
    margin-left: -0.75em;
    margin-top: 0.5em;
  }
  .character-card-bio {
    font-size: 0.9em;
  }
}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {
  .family-img {
    height: 100%;
  }
  .card-character-container {
    height: 100%;
    overflow-y: hidden;
  }
  .character-picture {
    width: 85px;
    height: 85px;
    margin-top: 10px;
  }
  .character-data-container {
    margin-left: -0.5em;
    margin-top: 0.75em;
  }
  .character-card-bio {
    font-size: 0.9em;
  }
}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {
  .page-section {
    margin-top: 6em;
  }
  .card-character-container {
    height: 500px;
  }
  .character-picture {
    width: 85px;
    height: 85px;
    margin-top: 10px;
  }
  .character-data-container {
    margin-left: -2.5em;
    margin-top: 0.75em;
  }
  .character-card-bio {
    font-size: 0.9em;
  }
}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {
  .page-section {
    margin-top: 6em;
  }
  .card-character-container {
    height: 500px;
  }
  .character-picture {
    width: 85px;
    height: 85px;
    margin-top: 10px;
  }
  .character-data-container {
    margin-left: 0em;
    margin-top: 0.5em;
  }
  .character-card-bio {
    font-size: 0.95em;
  }
}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {
  .page-section {
    margin-top: 6em;
  }
  .card-character-container {
    height: 500px;
  }
  .character-picture {
    width: 90px;
    height: 90px;
    margin-top: 10px;
  }
  .character-data-container {
    margin-left: -1em;
    margin-top: 0.75em;
  }
}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {
  .page-section {
    margin-top: 6em;
  }
  .card-character-container {
    height: 500px;
  }
  .character-picture {
    width: 90px;
    height: 90px;
    margin-top: 10px;
  }
  .character-data-container {
    margin-left: 2em;
    margin-top: 1.5em;
  }
}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
  .page-section {
    margin-top: 6em;
  }
  .card-character-container {
    height: 500px;
  }
  .character-picture {
    width: 90px;
    height: 90px;
    margin-top: 10px;
  }
  .character-data-container {
    margin-left: 2em;
    margin-top: 1em;
  }
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {
  .page-section {
    margin-top: 6em;
  }
  .card-character-container {
    height: 500px;
  }
  .character-picture {
    width: 90px;
    height: 90px;
    margin-top: 10px;
  }
  .character-data-container {
    margin-left: 2em;
    margin-top: 1em;
  }
}