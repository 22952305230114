.service-info-container {
  position: absolute;
  width: 500px;
  padding: 3em;
  background: var(--card-bg-color);
  border-radius: 14px;
  -webkit-box-shadow: var(--card-shadow);
  -moz-box-shadow: var(--card-shadow);
  box-shadow: var(--card-shadow);
}
.modal-back-home {
  position: absolute;
  top: 2em;
  left: 2em;
  text-align: left;
  text-decoration: none;
  color: var(--gray-color);
  cursor: pointer;
}
.modal-back-home:hover {
  color: var(--primary-color);
}
.title {
  color: var(--primary-color);
  font-weight: 900;
  margin: 30px 0px 10px 0px;
}
.sub-title {
  color: var(--secondary-color);
  font-weight: 300;
  margin: 0px 0px 20px 0px;
}
.content {
  margin-bottom: 35px;
}
.character-btns .btn {
  margin-top: 1em;
}
.btnInfo {
  position: fixed;
  width: 60px;
  height: 60px;
  top: calc(100vh / 3);
  left: calc((100vw / 2) + 20%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #ffffff;
  border: 4px solid var(--primary-color);
  box-shadow: 0 0 0 0 var(--primary-color);
  animation: pulse-orange 2s infinite;
  transform: scale(1);
  overflow-y: hidden;
  cursor: pointer;
}
.btnInfo img {
  width: 35px;  
  height: 35px;
}
@keyframes pulse-orange {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(246, 89, 17, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(246, 89, 17, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(246, 89, 17, 0);
  }
}
.btn-more-info {
  margin-left: 10px !important;
}
.btnClose {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  width: 30px;
  height: 30px;
  z-index: 1;
  cursor: pointer;
}
.btnClose img {
  width: 30px;
  height: 30px;
}

/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) { 
  .service-info-container {
    top: calc((95% / 10));
    right: calc((95% / 20));
  }
  .service-info-container {
    width: 185px;
  }
  .btn-custom {
    text-align: center;
  }
  .btn.btn-custom {
    text-align: center;
  }
  .btn-more-info {
    margin-top: 1em !important;
    margin-left: 0px !important;
  }
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
  .service-info-container {
    top: calc((95% / 10));
    right: calc((95% / 20));
  }
  .service-info-container {
    width: 200px;
  }
  .btn.btn-custom {
    text-align: center;
  }
  .btn-more-info {
    margin-top: 1em !important;
    margin-left: 0px !important;
  }
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {
  .service-info-container {
    top: calc((95% / 10));
    right: calc((95% / 20));
  }
  .service-info-container {
    width: 300px;
  }
  .btn-more-info {
    margin-top: 1em !important;
    margin-left: 0px !important;
  }
}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {
  .service-info-container {
    width: calc(60% / 1);
    top: calc((100% / 8));
    right: calc((95% / 25));
  }
}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {
  .service-info-container {
    top: calc((90% / 8));
    right: calc((90% / 20));
  }
}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {
  .service-info-container {
    top: calc((90% / 8));
    right: calc((90% / 20));
  }
}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {
  .service-info-container {
    top: calc((90% / 6));
    right: calc((90% / 12));
  }
}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {
  .service-info-container {
    top: calc((90% / 6));
    right: calc((90% / 12));
  }
}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
  .service-info-container {
    top: calc((90% / 6));
    right: calc((90% / 12));
  }
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {
  .service-info-container {
    top: calc((90% / 8));
    right: calc((90% / 6));
  }
}
