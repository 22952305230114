.page-list-container {
  height: 400px;
  overflow-y: auto;
  padding: 2em 3em;
  background: var(--card-bg-color);
  border-radius: 14px;
}

.page-content {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100vh;
}
.faq {
  background: url('../../../../public/images/background-3.png') no-repeat center center/cover;
}

/* Super extra small devices (phones, less than 320px) */
@media (max-width: 320px) { 
  .img-column.faq-img {
    height: 350px;
  }
  .img-column.faq-img img {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

/* extra small devices (mobile 5 inch, 320px and 575) */
@media (min-width: 321px) and (max-width: 574px) {
  .img-column.faq-img {
    height: 350px;
  }
  .img-column.faq-img img {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .page-list-container {
    height: 100%;
    overflow-y: hidden;
  }
}

/* small devices (mobile 7 inch, 575px and 768) */
@media (min-width: 575px) and (max-width: 767px) {
  .img-column.faq-img {
    height: 500px;
  }
  .img-column.faq-img img {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

/* medium devices (tablet 10 inch mini-desktops, 768px and 991) */
@media (min-width: 768px) and (max-width: 991px) {
  .img-column.faq-img {
    height: 550px;
  }
  .img-column.faq-img img {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

/* regular devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1023px) {
  .img-column.faq-img img {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

/* ipad pro (1024px and up) */
@media (min-width: 1024px) and (max-width: 1199px) {

}

/* large devices (large desktops, 1200px and 1365px) */
@media (min-width: 1200px) and (max-width: 1365px) {

}
/* extra large devices (extra large desktops, 1366pxpx and 1600px) */
@media (min-width: 1366px) and (max-width: 1559px) {

}

/* super large devices (super large desktops, 1600px and 1919px) */
@media (min-width: 1600px) and (max-width: 1919px) {
    
}

/* super extra large devices (super extra large desktops, 1920px and up) */
@media (min-width: 1920px) {

}
