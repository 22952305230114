.language-btn {
    background: none;
    position: absolute;
    top: calc(100vh / 3);
    cursor: pointer;
    z-index: 3;
}
.language-btn-image {
    background: #ffffff;
    display: flex;
    align-items: center;
    padding: 0.1rem 1rem 0.1rem 0;
    border-radius: 0 50px 50px 0;
}

.language-icon {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  margin: 5px;
}

.lang-text {
  font-weight: 600;
  margin: 1em 0.25em;
  color: var(--secondary-color);
}