.main-home {
  /* position: relative;
  width: 100%;
  height: 100vh; */
}

.text-ini {
  font-size: 1rem;
  font-weight: 400;
}

.btn {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0.8rem 1.2rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4) !important;
  border: 1.4px solid rgba(0, 0, 0, .2);
  -webkit-box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.57);
}

.btn:hover {
    color: rgba(255, 255, 255, 1) !important;
    background-color: rgba(0, 0, 0, 0.7);
}

.version {
  position: absolute;
  bottom: 0;
  left: 45%;
  color: rgba(0,0,0,.5);
}