.page-privacy {
  background: #ffffff;
  padding: 10px;
}

.page-privacy-container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.page-privacy-image {
  max-width: 666px;
  align-self: flex-start;
  flex-grow: 1;
}

.page-privacy-img {
  max-width: 666px;
}

.page-privacy-content {
  padding: 1rem 2rem;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.05);
  width: 800px;
  flex-grow: 4;
}

.page-privacy-item {
  padding: 5px;
}

.page-privacy-title {
  font-size: 1.3rem;
  color: var(--secondary-color);
  font-weight: 600;
  margin-bottom: 0.77rem;
}

.page-privacy-text {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.5rem;
}

.page-privacy-list {
  color: var(--gray-color);
}

.page-privacy-list li {
  margin: 1.2rem;
}



@media (min-width: 900px) and (max-width: 1200px) {
  .page-privacy-image {
    max-width: 300px;
    align-self: flex-start;
    flex-grow: 1;
  }

  .page-privacy-img {
    max-width: 300px;
  }
}

@media (min-width: 0px) and (max-width: 899px) {
  .page-privacy-image {
    display: none;
  }
}
