.pop-loader {
    position: fixed;
    background: rgba(0,0,0, 0.6);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.pop-loader-container {
    background: #f5f5f5;
    max-width: 800px;
    padding: 2rem;
    border-radius: 40px;
}

.pop-loader-title {
    font-size: 1.5rem;
    font-weight: 800;
    color: var(--primary-color);
}

.pop-loader-message {
    color: rgba(0,0,0,75);
}